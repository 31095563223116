<template>
  <div>
    <h2>{{ pageTitle }}</h2>
    <profile-form
      :key="profileId"
      :profile-id="profileId"
    />
  </div>
</template>

<script>
import ProfileForm from '@/components/Profiles/ProfileForm.vue'

export default {
  components: {
    ProfileForm,
  },
  computed: {
    profileId() {
      return this.$route.params.id
    },
    pageTitle() {
      return this.$route.meta.title
    },
  },
}
</script>
